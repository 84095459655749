import { Col, Row } from "react-bootstrap";
import "./FooterMobile.css";

const FooterMobile: React.FC = () => {
  return (
<Row className="footer-row-mobile d-flex justify-content-center">
  <Col xs={6}>
    <Row className="link-footer-mobile" style={{ fontSize: "15px", color: "#2F3B68" }}>
      Useful Links
    </Row>
    <Row className="link-footer-mobile" style={{ fontSize: "15px", color: "#000000", marginTop: "10%" }}>
      <a href="/privacypolicy">Privacy Policy</a>
    </Row>
    <Row className="link-footer-mobile" style={{ fontSize: "15px", color: "#000000" }}>
      <a href="/cookiespolicy">Cookies Policy</a>
    </Row>
    <Row className="link-footer-mobile" style={{ fontSize: "15px", color: "#000000" }}>
      <a href="/termsandconditions">Terms and Condition</a>
    </Row>
    <Row className="closing-footer-mobile" style={{ fontSize: "15px", marginTop: "8%" }}>
      Ependisis.com © 2024
    </Row>
  </Col>
  <Col xs={6} className="d-flex flex-column justify-content-start">
    <Row className="info-footer-mobile" style={{ fontSize: "15px", color: "#000000" }}>
      <a href="mailto:info@ependisis.com">info@ependisis.com</a>
    </Row>
    <Row className="info-footer-mobile" style={{ fontSize: "15px", color: "#000000", marginTop: "4%" }}>
      <a href="https://maps.app.goo.gl/HTgpzWAnNNJzLesU6">Gladstonos 31</a>
    </Row>
    <Row className="info-footer-mobile" style={{ fontSize: "15px", color: "#000000" }}>
      <a href="https://maps.app.goo.gl/HTgpzWAnNNJzLesU6">1095 Nicosia</a>
    </Row>
    <Row className="info-footer-mobile" style={{ fontSize: "15px", color: "#000000" }}>
      Cyprus
    </Row>
  </Col>
</Row>
  );
};

export default FooterMobile;
